import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';

// Define the initial state
const initialState = {
  username: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// Async thunk for login
export const login = createAsyncThunk(
  'session/login',
  async ({ username, password, system, campaign }, { rejectWithValue }) => {
    try {
      // Temporary login management
      if (
        (username === "theompi@gmail.com" && password === "Flowers(3343)") ||
        (username === "dfilip91@gmail.com" && password === "Psilos(1)" && system === "cota") ||
        (username === "halkos" && password === "p@ssw0rd" && system === "cota") ||
        (username === "Lucius" && password === "200300125" && system === "cota") ||
        (username === "Bobina" && password === "787742" && system === "cota") ||
        (username === "Mr.Ajax" && password === "Ajax" && system === "cota") ||
        (username === "Anaroch" && password === "asdfghjkl@" && system === "cota") ||
        (username === "Syvilla" && password === "6979625292" && system === "cota")
      ) {
        return { username, system, campaign };
      } else {
        throw new Error('Invalid username or password');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for logout
export const logout = createAsyncThunk('session/logout', async (_, { rejectWithValue }) => {
  try {
    return {};
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Create the slice
const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.username = action.payload.username;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = 'succeeded';
        state.username = '';
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default sessionSlice.reducer;
